import { post, get } from '../index'

export const tzn_tplbAPI = async (param = {}) => {  //精美图片分类列表
    const res = await post({
      payload: param,
      url: '/tznly/beautifulPictures/app/list',
      auth: true
    })
    if(res) {
      return res
    } else {
      return
    }
}

export const tzn_tpxqAPI = async (param = {}) => {  //精美图片详情展示
    const res = await post({
      payload: param,
      url: '/tznly/pictureDetail/app/layUIPage',
      auth: true
    })
    if(res) {
      return res
    } else {
      return
    }
}

export const tzn_revealAPI = async (id) => {  //珍稀鸟类图片详情介绍
  const res = await get({
    url: '/tznly/travelRecommend/app/get/'+id,
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}

export const tzn_jmspAPI = async (param = {}) => {  //精美视频详情展示
    const res = await post({
      payload: param,
      url: '/tznly/exquisiteVideo/app/layUIPage',
      auth: true
    })
    if(res) {
      return res
    } else {
      return
    }
}

export const tzn_article = async (param = {}) => {  //日出，观鸟文章列表获取
  const res = await post({
    payload: param,
    url: '/tznly/travelRecommend/app/getTravelRecommendList',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}

export const tzn_detail = async (id) => {  //日出，观鸟文章详情
  const res = await get({
    url: '/tznly/travelRecommend/app/get/'+id,
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}

export const tzn_diningAPI = async (param = {}) => {  //舌尖美味餐厅列表获取
  const res = await post({
    payload: param,
    url: '/tznly/foodRecommendation/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}

// 周边景区简介
export const tzn_Surrounding  = async (param = {}) => {  
  const res = await post({
    payload: param,
    url: '/tznly/peripheryScenery/app/layUIPage/',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}
// 全年活动
export const tzn_allYear  = async (param = {}) => {  
  const res = await post({
    payload: param,
    url: '/tznly/activityAllSeason/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}
// 景区新闻
export const tzn_news  = async (param = {}) => {  
  const res = await post({
    payload: param,
    url: '/tznly/dynamic_scenic_news/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}
// 景区公告
export const tzn_notice  = async (param = {}) => {  
  const res = await post({
    payload: param,
    url: '/tznly/dynamic_scenic_notice/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}